.advantages {
  position: relative;
  z-index: 1;
  padding-top: 80px;
  text-align: center;

  background-color: var(--background);

  background: linear-gradient(
      160deg,
      var(--background) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url(../../assets/images/111.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.title {
  padding: 0 50px;
  font-size: 30px;
  font-weight: 700;
  color: var(--green);
  text-align: center;
}

.subtitle {
  padding: 0 50px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.wrapper {
  padding: 50px 100px;
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.card {
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;

  min-height: 300px;
  padding: 20px;

  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);

  transition: all 0.5s ease;

  &:hover {
    backdrop-filter: blur(5px);
    transform: scale(105%);
  }
}

.cardTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;

  transition: all 0.5s ease;
}

.cardImg {
  margin-bottom: 20px;

  width: 60px;
  height: 60px;

  transition: all 0.5s ease;
}

.cardSubtitle {
  position: relative;
  height: 0;
  color: #fff;
  line-height: 120%;
  // transform: translateY(300px);
  padding-top: 20px;

  transition: all 0.5s ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100px;
    height: 2px;
    background-color: var(--green);
  }
}

@media (max-width: 900px) {
  .wrapper {
    padding: 50px 50px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 50px 20px;

    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  // .wrapper {
  //   padding: 20px 20px;
  //   grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  //   grid-template-columns: 1fr;
  // }

  .card {
    min-height: 250px;
  }

  .cardTitle {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .cardImg {
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
  }

  .cardSubtitle {
    padding-top: 10px;

    transition: all 0.5s ease;
  }
}

@media (max-width: 480px) {
  .wrapper {
    padding: 20px 20px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .card {
    min-height: 200px;
    text-align: center;
    align-items: center;
  }
}

@media (max-width: 400px) {
  .advantages {
    padding-top: 60px;
  }

  .title {
    padding: 0 20px;

    font-size: 20px;
  }

  .subtitle {
    padding: 0 20px;

    font-size: 20px;
  }
}
