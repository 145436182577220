.scrollTo {
  width: 60px !important;
  height: 60px !important;

  background-color: var(--green) !important;
  border-radius: 50% !important;
  border: 2px solid #fff !important;

  box-shadow: none !important;

  transition: all 0.5s ease;

  &:hover {
    transform: scale(110%);
  }
}
