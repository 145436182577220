.consult {
  padding: 80px 100px 25px 100px;
}

.title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 50px;
  color: var(--green);
}

.subtitle {
  margin-bottom: 35px;
  font-size: 18px;
  color: var(--dark);
}

.wrapper {
  display: flex;
  align-items: center;
}

.green {
  font-size: 1.1em;
  color: var(--green);
  font-weight: 600;
}

.img {
  width: 30%;
}

.formWrapper {
  width: 60%;
  min-width: 500px;
  max-width: 750px;
  margin-right: 50px;
}

.formItem {
  margin: 20px 0;
}

.input {
  width: 100%;
  background-color: transparent;
}

.flex {
  display: flex;
  gap: 36px;
  .formItem {
    width: 50%;
    margin: 0;
  }
}

.notice {
  margin-top: 15px;
  font-size: 10px;
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column-reverse;
  }
  .title {
    text-align: center;
  }
  .formWrapper {
    width: 100%;
    margin-right: 0;
    margin-top: 50px;
  }
  .img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .consult {
    padding: 80px 50px 25px 50px;
  }
  .formWrapper {
    min-width: 250px;
  }
}

@media (max-width: 576px) {
  .consult {
    padding: 80px 25px 25px 25px;
  }

  .formWrapper {
    padding: 20px;
  }
  .flex {
    flex-direction: column;
    gap: 20px;
    .formItem {
      width: 100%;
      margin: 0;
    }
  }
  .img {
    width: 80%;
  }
}
@media (max-width: 360px) {
  .consult {
    padding: 80px 0 25px 0;
  }
  .title {
    font-size: 35px;
  }
  .subtitle {
    text-align: center;
  }
}
