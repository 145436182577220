.footer {
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  min-height: 70px;

  background-color: var(--background);
}

.logo {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 35px;
}

.logoText {
  margin-left: 15px;
}

.logoTitle {
  font-size: 25px;
  color: #fff;
}

.logoSubtitle {
  font-size: 15px;
  color: var(--light);
}

.contacts {
  display: flex;
  align-items: center;
}

.phone {
  display: flex;
  align-items: center;

  color: #fff;
  font-size: 15px;
  font-weight: 700;

  transition: color 0.2s ease;

  cursor: pointer;
}

.contactsImg {
  margin-right: 10px;
  height: 35px;
}

.feedbackBtn {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 180px;
  height: 40px;
  padding: 2px 10px;
  margin-left: 20px;

  border-radius: 4px;

  background-color: var(--green);

  color: #fff;
  font-size: 15px;
  font-weight: 600;

  transition: all 0.2s ease;

  &:hover {
    background-color: var(--dark_green);
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .logo {
    margin: 20px 0;
  }

  .contacts {
    margin: 20px 0;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 0 20px;
  }

  .contacts {
    flex-direction: column-reverse;
    margin: 0 0 20px 0;
  }
  .feedbackBtn {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
