.reviews {
  padding: 80px 100px 50px 100px;
  box-shadow: 0px 0px 20px 10px rgba(34, 60, 80, 0.11) inset;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.img {
  width: 40%;
}

.title {
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 900;
  color: var(--dark);
}

.slider {
  margin: 20px 0;
}

.rewiev {
  margin: 20px 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid var(--dark);
  // border-radius: 4px;

  padding: 20px;
}

.rewievHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.author {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}

.text {
  padding: 20px;
  font-size: 14px;
  color: var(--dark);
  border: 1px solid var(--dark);
  border-radius: 4px;
  backdrop-filter: blur(5px);
  background-color: #ffffff86;
}

@media (max-width: 1024px) {
  .wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .title {
    text-align: center;
    margin-right: 0;
    margin-top: 20px;
  }

  .img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .reviews {
    padding: 80px 50px 50px 50px;
  }
  .rewievHead {
    flex-direction: column;
  }
  .author {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .reviews {
    padding: 80px 20px 50px 20px;
  }
  .img {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 20px;
  }
}
