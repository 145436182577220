@keyframes flicker {
  from {
    box-shadow: none;
  }
  25% {
    box-shadow: 0px 0px 15px 10px var(--green);
  }
  50% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0px 0px 15px 10px var(--green);
  }
  to {
    box-shadow: none;
  }
}

@keyframes radio {
  from {
    opacity: 1;
    width: 60px;
    height: 60px;
  }
  25% {
    opacity: 0.8;
    width: 120px;
    height: 120px;
  }
  50% {
    opacity: 0.6;
    width: 60px;
    height: 60px;
  }
  75% {
    opacity: 0.4;
    width: 120px;
    height: 120px;
  }
  to {
    opacity: 0;
    width: 60px;
    height: 60px;
  }
}
