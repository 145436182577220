.main {
  height: calc(100vh - 70px);
  padding: 100px 50px;
  background-color: rgb(30, 30, 30);
  color: #fff;

  // background-image: url(../../assets/images/main_bg.jpeg);
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position-y: center;
  // background-position-x: center;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(../../assets/images/main_bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.title {
  font-size: 60px;
  font-weight: 700;

  color: var(--green);
}

.subtitle {
  margin: 20px 0;
  color: var(--green);
  font-size: 25px;
}

.listTitle {
  margin-top: 40px;
  font-weight: 600;
}

.list {
  margin: 20px 0;
  list-style: none;
}

.listItem {
  padding: 5px 0;
}

@media (max-width: 1024px) {
  .img {
    display: none;
  }
}

@media (max-width: 900px) {
}

@media (max-width: 768px) {
  .title {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
}

@media (max-height: 576px) {
  .main {
    height: auto;
  }
}

@media (max-width: 400px) {
  .main {
    padding: 100px 20px;
  }
  .title {
    font-size: 30px;
  }
}
