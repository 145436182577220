.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  border-radius: 50%;

  font-size: 30px;
  color: #fff;

  transition: all 0.5s;
}
