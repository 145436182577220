:root {
  --light: #ebebeb;
  --orange: rgb(249, 120, 37);
  --green: rgb(27, 176, 66);
  --dark: #344f5a;
  --dark_green: rgb(28, 122, 53);
  --background: rgb(30, 30, 30);
  --whatsapp: rgb(51, 210, 107);
  --telegram: rgb(52, 164, 212);
  --viber: rgb(124, 83, 153);
}
