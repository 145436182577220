.header {
  position: sticky;
  top: 0;

  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 70px;

  box-shadow: 0px 4px 8px 4px rgba(52, 79, 90, 0.2);

  background-color: #fff;
}

.logo {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 35px;
}

.logoText {
  margin-left: 15px;
}

.logoTitle {
  font-size: 25px;
  font-weight: 700;
  color: #000;
}

.logoSubtitle {
  font-size: 15px;
  color: #606060;
}

.contacts {
  display: flex;
  align-items: center;
}

.phone {
  display: flex;
  align-items: center;
  margin-right: 20px;

  color: var(--dark);
  font-size: 15px;
  font-weight: 700;

  transition: color 0.2s ease;

  cursor: pointer;
}

.feedbackBtn {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 180px;
  height: 40px;
  padding: 2px 10px;
  margin-right: 20px;

  border-radius: 4px;

  background-color: var(--green);

  color: #fff;
  font-size: 15px;
  font-weight: 600;

  transition: all 0.2s ease;

  &:hover {
    background-color: var(--dark_green);
  }
}

.contactsImg {
  margin-right: 10px;
  height: 35px;
}

@media (max-width: 768px) {
  .phone {
    display: none;
  }

  .feedbackBtn {
    display: none;
  }
}

@media (max-width: 576px) {
  .header {
    padding: 0 20px;
  }
  .logoImg {
    height: 25px;
  }

  .logoText {
    margin-left: 10px;
  }

  .logoTitle {
    font-size: 20px;
  }

  .logoSubtitle {
    font-size: 10px;
  }
}
